.Toolbar {
    border-bottom: 1px solid #eee;
    background-color: #ffffff;
    font-family: 'Lato', sans-serif;
}

.agencement {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    padding-left: 15px;
}

.dropdownToggle {
    cursor: pointer;
    text-transform: capitalize;
}

.dropdownArrow {
    margin-top: -40px;
    right: 0px;
    color: #f1f1f1;
    text-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: -5;
    position: absolute;
}

.dropdownHeader {
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	border-radius: 0!important;
	background: #f1f1f1;
	margin-top: 20px;
}

.dropdownItem {
    padding: .5rem 1.5rem;
}

/* GLOBALS */
:global(.dropdown-item) {
    cursor: pointer;
    font-size: .9rem;
}
:global(.dropdown-item a) {
    color: black;
    text-decoration: none;
}
:global(.dropdown-item:active) {
    background-color: #d1eefc;
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .agencement { display: block; }
    .logo { text-align: center; padding: 0; margin-top: 1rem; }
    .dropdownToggle { display: none; }
    .nav { text-align: center; }
}

@media (max-width: 576px) {

}
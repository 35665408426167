.Button {
    border: none;
    border-radius: .25rem;
    font-size: .9em;
    cursor: pointer;
    padding: 10px 15px;
    margin-left: 15px;
    font-weight: bold;
    transition: all .1s;
    text-transform: uppercase;
    min-width: 7.5rem;
    height: 3rem;
    letter-spacing: .025rem;
    outline: none;
}

.Button:focus {
    outline: none;
}
.Button:hover,
.Button:active,
.Button.active {
    opacity: .8;
}
.Button:active {
    border: none;
}

.Button:first-of-type {
    margin-left: 0;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: white;
    background-color: rgb(115, 206, 73);
    border: .063rem solid #21a041;
}

.Continue {
    color: white;
    background-color: #ff8a1d;
    border: none;
}
.Continue:hover,
.Continue:active {
    box-shadow: inset 0 -0.125rem 0 0 rgba(154, 68, 0, .3);
}

.Danger {
    color: white;
    background-color: rgb(255, 37, 29);
    border: .063rem solid #e71b00;
}

.Outlined {
    border: .063rem solid #000;
    background-color: transparent;
    color: black;
}
.Outlined:hover,
.Outlined:active {
    background-color: #000;
    color: #fff;
}

.Shy {
    color: #284b7c;
    background-color: #fff;
    border: .063rem solid #becedd;
}
.Shy:hover,
.Shy:active {
    background-color: #d1eefc;
    box-shadow: inset 0 -0.125rem 0 0 rgba(40, 75, 124, .3);
}
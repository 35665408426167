.ParametersNav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ParametersNav li {
    margin: 15px 0;
    padding: .9rem;
}
.ParametersNav li:first-of-type {
    margin-top: 0;
}

.ParametersNav a {
    color: black;
    font-weight: 500;
    text-decoration: none;
}

.w500 {
    font-weight: 500;
}

.ParametersNav a:hover li,
.ParametersNav a:active,
.ParametersNav a.active li,
.w500:hover {
    text-decoration: none;
    background: #f4f4f4;
    cursor: pointer;
}
.NavigationItems {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
}

@media (max-width: 768px) {
    .NavigationItems { display: block; }
}
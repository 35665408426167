.NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
}

.NavigationItem a {
    color: black;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    text-decoration: none;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    /* background-color: rgb(235, 235, 235); */
    border-bottom: 4px solid #FF1F1F;
    color: #FF1F1F;
}

@media (max-width: 768px) {
    .NavigationItem { display: inline-block; padding: 16px 10px; }
}
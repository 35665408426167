.Input {
    width: 100%;
    margin: 10px 0;
}

.Label {
    font-weight: bold;
    display: block;
    margin: 1rem 0 .5rem 0;
    text-transform: uppercase;
    letter-spacing: .01rem;
    font-size: .9rem;
    color: #70767c;
}

.InputElement {
    border: 1px solid #ccc;
    padding: 5px 10px;
    display: block;
    width: 100%;
    height: 2.5rem;
    border-radius: .25rem;
}
.InputElement[readonly] {
    background-color: #e9ecef;
}

.ErrorMessage {
    color: red;
}

.Invalid {
    border: 1px solid red;
}
.Kana {
    font-size: 7rem;
}

.success {
    color: green;
}

.red {
    color: red;
}

.headResultTable {
    font-size: 1.1rem;
}

.resultTable {
    font-size: 1.4rem;
}
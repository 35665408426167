.Footer {
    padding: .9rem;
    background: #eee;
    font-size: .9em;
    margin-top: 3rem;
}

.Footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.Footer ul li {
    display: inline-block;
    margin: 0 20px;
}

.Footer ul li:first-of-type {
    margin: 0;
}

.Footer ul li a {
    color: rgb(54, 54, 54);
}

.Footer a {
    color: grey;
}

.Footer a:hover {
    color: #FF1F1F;
    text-decoration: none;
}

.Footer span {
    color: rgb(141, 141, 141);
}
.jumbotron::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.64));
    z-index: -1;
}

.jumbotron-title {
    max-width: 100%;
    min-width: 390px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .jumbotron-title { min-width: auto; text-overflow: inherit; overflow: inherit; }
    .jumbotron-title span { display: block; }
}
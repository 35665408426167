.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#content {
    flex-grow: 1;
}

h3 {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.toast-style {
    padding: 10px 15px;
}

.title-header {
    padding: 2rem 0 1rem 0;
    border-bottom: 1px solid #e1e5e7;
    font-size: 2rem;
    letter-spacing: .5px;
    margin-bottom: 1.25rem;
}

.title-display {
    font-size: 1.5rem;
}

.subtitle-display {
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 1rem;
}

.lesson-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: .4rem;
    letter-spacing: .01rem;
}

.normal-div-display {
    border-radius: 0 .8rem .8rem .8rem;
    box-shadow: 0 0 1.25rem 0 rgba(40, 75, 124, .08);
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}

@media (max-width: 768px) {
    .title-header-mobile {
        text-align: left!important;
    }
}
.cover {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;
    background: #ECE9E6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.app {
    width: 50%;
    border-radius: 19%;
}

.normalModeDisplay {
    border-radius: .8rem .8rem .8rem .8rem;
    box-shadow: 0 0 1.25rem 0 rgba(40, 75, 124, .08);
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}
.normalModeDisplay h1 {
    font-weight: 400;
    font-size: 1.9rem;
}

@media (max-width: 768px) {
    .challengeDiv button { margin-top: 10px; margin-left: 5px; margin-right: 5px; }
    .challengeDiv button:first-of-type { margin-left: 5px; }
}